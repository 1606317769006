.login{
    display: grid;
    grid-template-columns: 15% 70% 15%;
}

.input_ele{
    background-color: #1266f1;
    color: white;
    margin-bottom: 20px;
    width: 100%;
    height: 40px;
    border: none;
}

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}